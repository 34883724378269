import React from 'react'
import request from 'utils/request'
// import Bugsnag from '@bugsnag/js'
import Error from './routes/Error'

// Bugsnag.start('08c75ff533dbffb429666cc0fee51f2c')

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    try {
      request({
        method: 'POST',
        path: '/error',
        body: {
          error: `${error.stack} ${JSON.stringify(errorInfo)}`,
        },
      })
    } catch (unexpected) {
      console.error('unable to report an error...', unexpected)
    }
  }

  render() {
    if (this.state.hasError) {
      return <Error pathname="/unexpected" />
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children
  }
}

export default ErrorBoundary
