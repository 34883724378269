import { COLOR_CONSTANTS } from './index'

const COLOR_CONSTANTS_NEW = {
  WHITE: '#E9ECEF',
  COBALT: '#4184db',
  SAPPHIRE: '#f3f4f4',
}

export const colors = {
  primary: COLOR_CONSTANTS_NEW.COBALT, // COLOR_CONSTANTS.COBALT,
  primaryText: COLOR_CONSTANTS_NEW.WHITE,
  secondaryText: COLOR_CONSTANTS_NEW.SAPPHIRE, // COLOR_CONSTANTS.SAPPHIRE,
  ternaryText: COLOR_CONSTANTS.VENUS_MIST,
  denimText: COLOR_CONSTANTS_NEW.WHITE, // this is a revert of COLOR_CONSTANTS.DENIM
  white: COLOR_CONSTANTS_NEW.WHITE, // white color is used
  primary_text_reverted: COLOR_CONSTANTS.NAVY,
  error: COLOR_CONSTANTS.FLUORESCENT_RED,
  warning: COLOR_CONSTANTS.PUMPKIN_PIE,
  success: COLOR_CONSTANTS.JUNGLE_GREEN,
  background: COLOR_CONSTANTS.DENIM, // main background color body and etc
  background_card: COLOR_CONSTANTS.SALUTE, // card background color
  background_card_secondary: COLOR_CONSTANTS.DENIM, // card background color
  background_modal_overlay: COLOR_CONSTANTS.DENIM, // all modals have an overlay background
  background_modal_header: COLOR_CONSTANTS.DENIM, // modal header and footer backgrounds
  background_modal: COLOR_CONSTANTS.SALUTE, // modal body background
  background_item_hover: COLOR_CONSTANTS.DENIM, // hovering over any tr or item

  cliploader_color: COLOR_CONSTANTS.COBALT, // color of the spinner

  border_color: COLOR_CONSTANTS.COSMIC_ENERGY, // color of border
  border_color_hover: COLOR_CONSTANTS.NAVY,
  border_color_light: COLOR_CONSTANTS.VENUS_MIST, // color of border light and some backgrounds
  border_color_light_hover: COLOR_CONSTANTS.CERULEAN,

  background_badge_active: COLOR_CONSTANTS.JUNGLE_GREEN, // badge active background
  background_badge_inactive: COLOR_CONSTANTS.COSMIC_ENERGY, // badge inactive background

  background_slider: COLOR_CONSTANTS.SALUTE, // background for sliders left/right
  background_item_light: COLOR_CONSTANTS.DENIM, // background color for items inside a card

  input_placeholder_color: COLOR_CONSTANTS.COSMIC_ENERGY, // placeholder color for input, textarea and etc
  icon_color_gray: COLOR_CONSTANTS.COSMIC_ENERGY, // default icon color is gray in the system, fill and stroke
  icon_background: COLOR_CONSTANTS.DENIM, // default background for all icon wrappers and small controls
  icon_background_hover: COLOR_CONSTANTS.DAISY, // this is for icons as buttons (rounded)

  box_shadow: COLOR_CONSTANTS.WHITE, // shadows of boxes and etc

  background_loading_progress: COLOR_CONSTANTS.SALUTE, // background of cliploader wrapper

  progress_line_background: COLOR_CONSTANTS.SPRUCE, // progress lines colors
  progress_line_color: COLOR_CONSTANTS.COBALT, // progress lines colors

  composer_icons_color: COLOR_CONSTANTS.GLACIER_PEARL, // hashtag, camera, at and etc
  composer_variable_color: COLOR_CONSTANTS.ROCKMAN_BLUE, // color of variables in composer textarea
  composer_selected_text_range_color: COLOR_CONSTANTS.COSMIC_ENERGY, // in composer textarea selected text color

  background_internal_textarea: COLOR_CONSTANTS.CARROT, // background color of internal textarea box

  text_slash_divider_color: COLOR_CONSTANTS.VIOLET_WHIMSEY, // color if "/" divider between texts

  switch_component_background_on: COLOR_CONSTANTS.COBALT, // switch component on
  switch_component_background_off: COLOR_CONSTANTS.COSMIC_ENERGY, // switch component off

  primary_button_border: COLOR_CONSTANTS.COSMIC_ENERGY, // border of primary/gradient button
  primary_button_background: 'linear-gradient(90deg, #0b60da 0%, #2482fd 100%)', // background of primary/gradient button
  gray_button_background: COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL, // background of gray button
  gray_button_color: COLOR_CONSTANTS.SALUTE, // text color inside of gray button

  dashboard_statistics_alerts_text_color: COLOR_CONSTANTS.CERULEAN, // info color on dashboard texts in the right section

  checkbox_background: COLOR_CONSTANTS.WHITE, // '#4184db',
  checkbox_color: COLOR_CONSTANTS.NAVY, // '#4184db',
}
