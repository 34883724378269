import React from 'react'
import PropTypes from 'prop-types'
import App from 'App'

const FullScreen = ({ user, children }) => <App user={user}>{children}</App>

FullScreen.defaultProps = {
  user: null,
}

FullScreen.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
}

export default FullScreen
