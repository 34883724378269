import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import VistaSocialTextLogo from 'svg/vistasocial/vistasocial.svg'
import Checkmark from 'svg/checkmark.svg'
import Edit from 'svg/edit.svg'
import Bio from 'svg/bio.svg'
import Trash from 'svg/trash.svg'
import Logout from 'svg/logout.svg'
import Canva from 'svg/canva_color.svg'
import QuestionMarkOutlined from 'svg/questionmark_outlined.svg'
import ArrowDropdown from 'svg/arrow_dropdown.svg'
import Flare from 'svg/flare.svg'
import Dismiss from 'svg/dismiss.svg'
import OpenInNew from 'svg/open_in_new.svg'
import Star from 'svg/star.svg'
import StarEmpty from 'svg/star_empty.svg'
import HeartFull from 'svg/heart_full.svg'
import HeartOutlined from 'svg/heart_outlined.svg'
import Lock from 'svg/lock.svg'
import LockOpen from 'svg/lock_open.svg'
import ClearCircle from 'svg/clearCircle.svg'
import Send from 'svg/send.svg'
import SendOutlined from 'svg/send_outlined.svg'
import Sync from 'svg/sync.svg'
import LoadingElipse from 'svg/loading_elipse.svg'
import Warning from 'svg/warning.svg'
import Hamburger from 'svg/hamburger.svg'
import Company from 'svg/company.svg'
import TrendingUp from 'svg/trending_up.svg'
import GroupEqual from 'svg/group_equal.svg'
import MouseCursorOutlined from 'svg/mouse_cursor_outlined.svg'
import SmsChatOutlined from 'svg/sms_chat_outlined.svg'
import FavoriteComment from 'svg/favorite_comment.svg'
import Search from 'svg/search.svg'
import Camera from 'svg/camera.svg'
import Image from 'svg/image.svg'
import Activate from 'svg/activate.svg'
import CreditCard from 'svg/creditCard.svg'
import Flag from 'svg/flag.svg'
import Smile from 'svg/smile.svg'
import VideoCamera from 'svg/videocamera.svg'
import Film from 'svg/film.svg'
import HashTag from 'svg/hashtag.svg'
import Link from 'svg/link.svg'
import Minus from 'svg/minus.svg'
import Plus from 'svg/plus.svg'
import At from 'svg/at.svg'
import Text from 'svg/text.svg'
import Puzzle from 'svg/puzzle.svg'
import Pause from 'svg/pause.svg'
import Gear from 'svg/gear.svg'
import Reply from 'svg/reply.svg'
import Download from 'svg/download.svg'
import File from 'svg/file.svg'
import FileMove from 'svg/file_move.svg'
import Carousel from 'svg/carousel.svg'
import MixedMedia from 'svg/mixedmedia.svg'
import Upload from 'svg/upload.svg'
import News from 'svg/news.svg'
import GIF from 'svg/gif.svg'
import Avatar from 'svg/avatar.svg'
import Clavis from 'svg/clavis.svg'
import Drag from 'svg/drag.svg'
import Save from 'svg/save.svg'
import Undo from 'svg/undo.svg'
import Splash from 'svg/splash.svg'
import AI from 'svg/ai.svg'
import Snippet from 'svg/snippet.svg'
import Brackets from 'svg/brackets.svg'
import MultipleAttachments from 'svg/multiple_attachments.svg'
import GoogleDrive from 'svg/google_drive_color.svg'
import Dropbox from 'svg/dropbox_color.svg'
import OneDrive from 'svg/onedrive_color.svg'
import CalendarICS from 'svg/calendar_ics.svg'
import Copy from 'svg/copy.svg'
import Info from 'svg/info.svg'
import Workflow from 'svg/workflow.svg'
import Bell from 'svg/bell.svg'
import Security from 'svg/security.svg'
import Integration from 'svg/integration.svg'
import Note from 'svg/note.svg'
import Phone from 'svg/phone.svg'
import Rocket from 'svg/rocket.svg'
import Folder from 'svg/folder.svg'
import ThreadsLike from 'svg/threads_like.svg'
import ThreadsComment from 'svg/threads_comment.svg'
import ThreadsRepost from 'svg/threads_repost.svg'
import ThreadsShare from 'svg/threads_share.svg'
import SelectAll from 'svg/select_all.svg'
import Idea from 'svg/idea.svg'
import Shield from 'svg/shield.svg'
import More from 'svg/more.svg'
import Export from 'svg/export.svg'
import VistaSocialList from 'svg/vistasocial/list.svg'
import VistaSocialCalendar from 'svg/vistasocial/calendar.svg'
// import VistaSocialInbox from 'svg/vistasocial/inbox.svg'
import VistaSocialInbox from 'svg/vistasocial/notifications.svg'
import VistaSocialReport from 'svg/vistasocial/report.svg'
import VistaSocialPaperPlane from 'svg/vistasocial/paper_plane.svg'
import VistaSocialRadio from 'svg/vistasocial/radio.svg'
import VistaSocialReview from 'svg/vistasocial/review.svg'
import VistaSocialTask from 'svg/vistasocial/task.svg'
import VistaSocialVistaPage from 'svg/vistasocial/vistapage.svg'
import VistaSocialVistaPageAdd from 'svg/vistasocial/vistapage_add.svg'
import VistaSocialAdvocacy from 'svg/vistasocial/advocacy.svg'
import VistaSocialChevronDown from 'svg/vistasocial/chevron_down.svg'
import VistaSocialChevronUp from 'svg/vistasocial/chevron_up.svg'
import VistaSocialChevronRight from 'svg/vistasocial/chevron_right.svg'
import VistaSocialPlus from 'svg/vistasocial/plus.svg'
import VistaSocialPicture from 'svg/vistasocial/picture.svg'
import VistaSocialLandscape from 'svg/vistasocial/landscape.svg'
import VistaSocialText from 'svg/vistasocial/text.svg'
import VistaSocialLabel from 'svg/vistasocial/label.svg'
import VistaSocialError from 'svg/vistasocial/error.svg'
import VistaSocialAvatar from 'svg/vistasocial/avatar.svg'
import VistaSocialEntitiesAndGroups from 'svg/vistasocial/entities_and_groups.svg'
import VistaSocialConnectedProfiles from 'svg/vistasocial/connected_profiles.svg'
import VistaSocialPublishingSettings from 'svg/vistasocial/publishing_settings.svg'
import VistaSocialTeamMembers from 'svg/vistasocial/team_members.svg'
import VistaSocialDashboard from 'svg/vistasocial/home.svg'
import VistaSocialPin from 'svg/vistasocial/pin.svg'
import VistaSocialPinFilled from 'svg/vistasocial/pin_filled.svg'
import VistaSocialDollar from 'svg/vistasocial/dollar.svg'
import VistaSocialSupport from 'svg/vistasocial/support.svg'
import VistaSocialReportSocialMedia from 'svg/vistasocial/report_social_media.svg'
import VistaSocialReportCompetitor from 'svg/vistasocial/report_competitor.svg'
import VistaSocialReportTasks from 'svg/vistasocial/report_tasks.svg'
import VistaSocialReportAdvocacy from 'svg/vistasocial/report_advocacy.svg'
import VistaSocialReportSentiment from 'svg/vistasocial/report_sentiment.svg'
import VistaSocialReportBenchmark from 'svg/vistasocial/report_benchmark.svg'
import VistaSocialReportReviews from 'svg/vistasocial/report_reviews.svg'
import VistaSocialReportPosts from 'svg/vistasocial/report_posts.svg'
import VistaSocialRefresh from 'svg/vistasocial/refresh.svg'
import VistaSocialBrandVoice from 'svg/vistasocial/brand_voice.svg'
import VistaSocialPolicy from 'svg/vistasocial/policy.svg'
import VistaSocialFilter from 'svg/vistasocial/filter.svg'
import VistaPageAppearance from 'svg/vista_page/appearance.svg'
import VistaPageLayout from 'svg/vista_page/layout.svg'
import VistaPageAdd from 'svg/vista_page/add.svg'
import VistaPageSettings from 'svg/vista_page/settings.svg'
import VistaPageStatistics from 'svg/vista_page/statistics.svg'
import VistaPagQR from 'svg/vista_page/qr.svg'

import VistaSocialDashboardBell from 'svg/vistasocial/dashboard/bell.svg'
import VistaSocialDashboardFile from 'svg/vistasocial/dashboard/file.svg'
import VistaSocialDashboardArrow from 'svg/vistasocial/dashboard/arrow.svg'

const createIcon = (Svg) => {
  const Icon = ({ className, color, width, height, fill, stroke, viewBox, theme }) => {
    const updatedProps = {}

    if (width) {
      updatedProps.width = width
    }

    if (height) {
      updatedProps.height = height
    }

    if (viewBox) {
      updatedProps.viewBox = viewBox
    }

    if (fill) {
      updatedProps.fill = theme.colors[fill] || fill
    } else if (color) {
      updatedProps.fill = theme.colors[color] || color
    }

    if (stroke) {
      updatedProps.stroke = theme.colors[stroke] || stroke
    }

    return <Svg className={className} color={color} {...updatedProps} />
  }

  Icon.defaultProps = {
    className: '',
    color: '',
    width: '',
    height: '',
    fill: '',
    stroke: '',
    viewBox: '',
  }
  Icon.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    fill: PropTypes.string,
    stroke: PropTypes.string,
    viewBox: PropTypes.string,
    theme: PropTypes.object.isRequired,
  }

  return withTheme(Icon)
}

export default {
  VistaSocialTextLogo: createIcon(VistaSocialTextLogo),
  Checkmark: createIcon(Checkmark),
  Edit: createIcon(Edit),
  Bio: createIcon(Bio),
  Trash: createIcon(Trash),
  Logout: createIcon(Logout),
  QuestionMarkOutlined: createIcon(QuestionMarkOutlined),
  Canva: createIcon(Canva),
  ArrowDropdown: createIcon(ArrowDropdown),
  Flare: createIcon(Flare),
  Dismiss: createIcon(Dismiss),
  OpenInNew: createIcon(OpenInNew),
  Star: createIcon(Star),
  StarEmpty: createIcon(StarEmpty),
  HeartFull: createIcon(HeartFull),
  HeartOutlined: createIcon(HeartOutlined),
  Lock: createIcon(Lock),
  LockOpen: createIcon(LockOpen),
  ClearCircle: createIcon(ClearCircle),
  Send: createIcon(Send),
  SendOutlined: createIcon(SendOutlined),
  Sync: createIcon(Sync),
  LoadingElipse: createIcon(LoadingElipse),
  Warning: createIcon(Warning),
  Hamburger: createIcon(Hamburger),
  Company: createIcon(Company),
  TrendingUp: createIcon(TrendingUp),
  GroupEqual: createIcon(GroupEqual),
  MouseCursorOutlined: createIcon(MouseCursorOutlined),
  SmsChatOutlined: createIcon(SmsChatOutlined),
  FavoriteComment: createIcon(FavoriteComment),
  Search: createIcon(Search),
  Camera: createIcon(Camera),
  Image: createIcon(Image),
  Activate: createIcon(Activate),
  CreditCard: createIcon(CreditCard),
  Flag: createIcon(Flag),
  Smile: createIcon(Smile),
  VideoCamera: createIcon(VideoCamera),
  Film: createIcon(Film),
  HashTag: createIcon(HashTag),
  Link: createIcon(Link),
  Minus: createIcon(Minus),
  Plus: createIcon(Plus),
  At: createIcon(At),
  Text: createIcon(Text),
  Puzzle: createIcon(Puzzle),
  Pause: createIcon(Pause),
  Gear: createIcon(Gear),
  Reply: createIcon(Reply),
  Download: createIcon(Download),
  File: createIcon(File),
  FileMove: createIcon(FileMove),
  Carousel: createIcon(Carousel),
  MixedMedia: createIcon(MixedMedia),
  Upload: createIcon(Upload),
  News: createIcon(News),
  GIF: createIcon(GIF),
  Avatar: createIcon(Avatar),
  Clavis: createIcon(Clavis),
  Drag: createIcon(Drag),
  Save: createIcon(Save),
  Undo: createIcon(Undo),
  Splash: createIcon(Splash),
  AI: createIcon(AI),
  Snippet: createIcon(Snippet),
  Brackets: createIcon(Brackets),
  MultipleAttachments: createIcon(MultipleAttachments),
  GoogleDrive: createIcon(GoogleDrive),
  Dropbox: createIcon(Dropbox),
  OneDrive: createIcon(OneDrive),
  CalendarICS: createIcon(CalendarICS),
  Copy: createIcon(Copy),
  Info: createIcon(Info),
  Workflow: createIcon(Workflow),
  Bell: createIcon(Bell),
  Security: createIcon(Security),
  Integration: createIcon(Integration),
  Note: createIcon(Note),
  Phone: createIcon(Phone),
  Rocket: createIcon(Rocket),
  Folder: createIcon(Folder),
  ThreadsLike: createIcon(ThreadsLike),
  ThreadsComment: createIcon(ThreadsComment),
  ThreadsRepost: createIcon(ThreadsRepost),
  ThreadsShare: createIcon(ThreadsShare),
  SelectAll: createIcon(SelectAll),
  Idea: createIcon(Idea),
  Shield: createIcon(Shield),
  More: createIcon(More),
  Export: createIcon(Export),
  VistaSocialList: createIcon(VistaSocialList),
  VistaSocialCalendar: createIcon(VistaSocialCalendar),
  VistaSocialInbox: createIcon(VistaSocialInbox),
  VistaSocialReport: createIcon(VistaSocialReport),
  VistaSocialPaperPlane: createIcon(VistaSocialPaperPlane),
  VistaSocialReview: createIcon(VistaSocialReview),
  VistaSocialRadio: createIcon(VistaSocialRadio),
  VistaSocialTask: createIcon(VistaSocialTask),
  VistaSocialVistaPage: createIcon(VistaSocialVistaPage),
  VistaSocialVistaPageAdd: createIcon(VistaSocialVistaPageAdd),
  VistaSocialAdvocacy: createIcon(VistaSocialAdvocacy),
  VistaSocialChevronDown: createIcon(VistaSocialChevronDown),
  VistaSocialChevronUp: createIcon(VistaSocialChevronUp),
  VistaSocialChevronRight: createIcon(VistaSocialChevronRight),
  VistaSocialPlus: createIcon(VistaSocialPlus),
  VistaSocialPicture: createIcon(VistaSocialPicture),
  VistaSocialLandscape: createIcon(VistaSocialLandscape),
  VistaSocialText: createIcon(VistaSocialText),
  VistaSocialLabel: createIcon(VistaSocialLabel),
  VistaSocialError: createIcon(VistaSocialError),
  VistaSocialAvatar: createIcon(VistaSocialAvatar),
  VistaSocialEntitiesAndGroups: createIcon(VistaSocialEntitiesAndGroups),
  VistaSocialConnectedProfiles: createIcon(VistaSocialConnectedProfiles),
  VistaSocialPublishingSettings: createIcon(VistaSocialPublishingSettings),
  VistaSocialTeamMembers: createIcon(VistaSocialTeamMembers),
  VistaSocialDashboard: createIcon(VistaSocialDashboard),
  VistaSocialPin: createIcon(VistaSocialPin),
  VistaSocialPinFilled: createIcon(VistaSocialPinFilled),
  VistaSocialDollar: createIcon(VistaSocialDollar),
  VistaSocialSupport: createIcon(VistaSocialSupport),
  VistaSocialReportSocialMedia: createIcon(VistaSocialReportSocialMedia),
  VistaSocialReportCompetitor: createIcon(VistaSocialReportCompetitor),
  VistaSocialReportTasks: createIcon(VistaSocialReportTasks),
  VistaSocialReportAdvocacy: createIcon(VistaSocialReportAdvocacy),
  VistaSocialReportSentiment: createIcon(VistaSocialReportSentiment),
  VistaSocialReportBenchmark: createIcon(VistaSocialReportBenchmark),
  VistaSocialReportReviews: createIcon(VistaSocialReportReviews),
  VistaSocialReportPosts: createIcon(VistaSocialReportPosts),
  VistaSocialRefresh: createIcon(VistaSocialRefresh),
  VistaSocialBrandVoice: createIcon(VistaSocialBrandVoice),
  VistaSocialPolicy: createIcon(VistaSocialPolicy),
  VistaSocialFilter: createIcon(VistaSocialFilter),
  VistaPageAppearance: createIcon(VistaPageAppearance),
  VistaPageLayout: createIcon(VistaPageLayout),
  VistaPageAdd: createIcon(VistaPageAdd),
  VistaPageSettings: createIcon(VistaPageSettings),
  VistaPageStatistics: createIcon(VistaPageStatistics),
  VistaPagQR: createIcon(VistaPagQR),
  VistaSocialDashboardBell: createIcon(VistaSocialDashboardBell),
  VistaSocialDashboardFile: createIcon(VistaSocialDashboardFile),
  VistaSocialDashboardArrow: createIcon(VistaSocialDashboardArrow),
}
