import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import NextNprogress from 'nextjs-progressbar'
import Alert from 'react-s-alert'
import { theme, GlobalStyles } from 'theme'
import { LIGHT_THEME, COLOR_THEME_OPTIONS, LOCAL_STORAGE_KEY_THEME } from 'consts'
import AlertTemplate from 'shared/AlertTemplate'
import '@material-ui/styles'
import '@reach/dialog/styles.css'
import 'sanitize.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-s-alert/dist/s-alert-default.css'
import 'react-quill/dist/quill.snow.css'
import 'fonts.css'
import 'slick-carousel/slick/slick.css'
import 'tui-image-editor/dist/tui-image-editor.css'
import 'tui-color-picker/dist/tui-color-picker.css'
import 'intro.js/introjs.css'
import withoutPageDataProps from 'utils/withoutPageDataProps'
import ErrorBoundary from './ErrorBoundary'

const App = ({ user, children }) => {
  const [selectedTheme, setSelectedTheme] = useState({
    ...theme,
    colors: { ...COLOR_THEME_OPTIONS[LIGHT_THEME] },
    name: LIGHT_THEME,
  })

  useEffect(() => {
    const { settings } = user || {}

    let { color_theme: user_color_theme } = settings || {}

    if (!user_color_theme) {
      const localStorageTheme = localStorage.getItem(LOCAL_STORAGE_KEY_THEME)

      if (localStorageTheme) {
        user_color_theme = localStorageTheme
      }
    }

    if (
      user_color_theme &&
      COLOR_THEME_OPTIONS[user_color_theme] &&
      !selectedTheme.must_not_auto_change &&
      selectedTheme.name !== user_color_theme
    ) {
      selectedTheme.colors = { ...COLOR_THEME_OPTIONS[user_color_theme] }

      selectedTheme.name = user_color_theme

      setSelectedTheme({ ...selectedTheme })

      localStorage.setItem(LOCAL_STORAGE_KEY_THEME, user_color_theme)
    }
  }, [selectedTheme])

  const handleClickChangeTheme = (event) => {
    const { detail = {} } = event
    const { theme_color } = detail || {}

    if (theme_color && COLOR_THEME_OPTIONS[theme_color] && selectedTheme.name !== theme_color) {
      localStorage.setItem(LOCAL_STORAGE_KEY_THEME, theme_color)

      if (!user.settings) {
        user.settings = {}
      }

      user.settings.color_theme = theme_color

      delete selectedTheme.must_not_auto_change

      selectedTheme.colors = { ...COLOR_THEME_OPTIONS[theme_color] }

      selectedTheme.name = theme_color
      setSelectedTheme({ ...selectedTheme })
    }
  }

  const handleChangeThemeForTests = (event) => {
    if (process.env.NODE_ENV === 'development') {
      if (event.altKey && event.shiftKey && event.keyCode === 69) {
        const objectKeysColorTheme = Object.keys(COLOR_THEME_OPTIONS)

        const colorThemeIndex = objectKeysColorTheme.findIndex((key) => key === selectedTheme.name)

        let theme_color = COLOR_THEME_OPTIONS[LIGHT_THEME]
        let theme_name

        if (objectKeysColorTheme[colorThemeIndex + 1]) {
          theme_color = COLOR_THEME_OPTIONS[objectKeysColorTheme[colorThemeIndex + 1]]
          theme_name = objectKeysColorTheme[colorThemeIndex + 1]
        } else {
          theme_color = COLOR_THEME_OPTIONS[objectKeysColorTheme[0]]
          // eslint-disable-next-line prefer-destructuring
          theme_name = objectKeysColorTheme[0]
        }

        if (theme_name && selectedTheme.name !== theme_name) {
          selectedTheme.must_not_auto_change = true

          selectedTheme.colors = { ...theme_color }

          selectedTheme.name = theme_name
          setSelectedTheme({ ...selectedTheme })


          window.dispatchEvent(
            new CustomEvent('handleChangeThemeForTests', { detail: { theme_color: theme_name } })
          )
        }
      }
    }
  }

  useEffect(() => {
    window.addEventListener('handleUpdateUserTheme', handleClickChangeTheme)
    window.addEventListener('keydown', handleChangeThemeForTests)

    return () => {
      window.removeEventListener('handleUpdateUserTheme', handleClickChangeTheme)
      window.removeEventListener('keydown', handleChangeThemeForTests)
    }
  }, [])

  return (
    <ErrorBoundary>
      <ThemeProvider theme={selectedTheme}>
        <React.Fragment>
          <NextNprogress
            color={selectedTheme.colors.primary}
            startPosition={0.3}
            stopDelayMs={200}
            options={{ easing: 'ease', speed: 500, showSpinner: false }}
            height={3}
          />
          <GlobalStyles theme={selectedTheme} />
          {children}
          <Alert stack={{ limit: 3 }} contentTemplate={AlertTemplate} position="top-right" />
        </React.Fragment>
      </ThemeProvider>
    </ErrorBoundary>
  )
}

App.defaultProps = {
  user: null,
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  user: PropTypes.object,
}

export default withoutPageDataProps(App)
