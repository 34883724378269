import fetch from 'node-fetch'
import https from 'https'
import Alert from 'react-s-alert'
import { ERROR_MESSAGE } from '../consts'

// TODO: remove this in production
const agent = new https.Agent({
  rejectUnauthorized: false,
})

const request = async ({
  context = {},
  path,
  method = 'GET',
  body,
  contentType = 'application/json',
  sendFile = false,
  file = false,
  show_error = true,
  api_url = process.env.NEXT_PUBLIC_BACKEND_URL,
}) => {
  // TODO: rework this into a HOC so context is not needed in every request
  // TODO: add automatic logout if the token is invalid / not present + redirect to index
  // TODO: add a logout route to backend that invalidates the jwt token
  // const token = nextCookie(context).token || cookie.get('token')

  const { res, req } = context

  // console.log(req.headers)

  // const domain = req ? req.headers['x-forwarded-domain'] : undefined

  // console.log(req.headers)

  const cookie = req ? req.headers.cookie : document.cookie

  const headers = {
    Accept: 'application/json, text/plain, */*',
    'Content-Type': contentType,
    'User-Agent': 'VistaSocialUI',
  }

  // if (domain) {
  //   headers.referer = `https://${domain}`
  //   headers.Origin = `https://${domain}`
  // } else {
  //   headers.referer = process.env.NEXT_PUBLIC_SITE_URL
  //   headers.Origin = process.env.NEXT_PUBLIC_SITE_URL
  // }

  headers.referer = process.env.NEXT_PUBLIC_SITE_URL
  headers.Origin = process.env.NEXT_PUBLIC_SITE_URL

  if (sendFile) {
    delete headers['Content-Type']
  }

  if (cookie) {
    headers.cookie = cookie

    const parsed_cookies = cookie.split('; ').map((value) => {
      const split = value.split('=')

      return {
        key: split[0],
        value: split[1],
      }
    })

    // console.log('parsed cookies', parsed_cookies)

    const jwt_cookie = parsed_cookies.find((item) => item.key === 'jwt')

    // console.log('jwt_cookie', jwt_cookie)

    if (jwt_cookie && jwt_cookie.value) {
      headers.authorization = `Bearer ${jwt_cookie.value}`
    }
  } else {
    // console.log('no cookies')
  }

  try {
    // let baseUrl

    // if (req) {
    //   // serverside
    //   // if (domain) {
    //   //   baseUrl = `https://${domain}/api`
    //   // } else {
    //   //   baseUrl = process.env.NEXT_PUBLIC_BACKEND_URL
    //   // }
    //   baseUrl = api_url
    // } else {
    //   baseUrl = '/api'
    // }

    const url = `${api_url}/${path}`

    const response = await fetch(url, {
      agent,
      method,
      // ...(body && { body: body }),
      ...(body && { body: !sendFile ? JSON.stringify(body) : body }),
      headers,
    })

    if (response.status >= 300) {
      if (res) {
        if (response.status >= 300 && response.status < 399) {
          console.error(`access defined ${path}`)
          res.redirect('/denied')
        } else if (response.status >= 400 && response.status < 499) {
          console.error(`missing resource ${path}`)
          res.redirect('/missing')
        } else {
          console.error(`unexpected error [${response.status}], [${response.statusText}] during request ${path}`)
          res.redirect('/unexpected')
        }
      } else if (response.status === 413) {
        return { error: 'Your upload is too large. Please limit it to 500mb.' }
      } else {
        if (show_error) {
          Alert.error(`${ERROR_MESSAGE} (${response.status})`, { timeout: 5000 })
        }
        return response.json()
      }
    } else {
      if (file) {
        return response
      }
      return response.json()
    }
  } catch (error) {
    console.error(`unable to execute request ${path} due to ${error.stack}`)

    if (res) {
      res.redirect('/unexpected')
    } else {
      throw error
    }
  }

  return undefined
}

export default request
