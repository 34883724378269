import styled from 'styled-components'
import {
  space,
  color,
  fontSize,
  fontWeight,
  textAlign,
  lineHeight,
  width,
  height,
  fontStyle,
  fontFamily,
  display,
} from 'styled-system'
import { fontSizes, fontWeights, lineHeights } from 'theme'

const styledFunctions = [
  space,
  color,
  fontSize,
  fontWeight,
  fontStyle,
  textAlign,
  lineHeight,
  height,
  width,
  fontFamily,
  display,
]

export const H1 = styled.h1`
  margin: 0;
  font-size: ${fontSizes.xxxxl}; /* figma: 36px */
  font-weight: ${fontWeights.bold};
  line-height: ${lineHeights.s};
  ${styledFunctions};
`

export const H2 = styled.h2`
  margin: 0;
  font-size: ${fontSizes.xxxl}; /* figma: 32px */
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.s};
  ${styledFunctions}
`

export const H3 = styled.h3`
  margin: 0;
  font-size: ${fontSizes.xxl}; /* figma: 24px */
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.s};
  ${styledFunctions}
`

export const H4 = styled.h4`
  margin: 0;
  font-size: ${fontSizes.l}; /* figma: 20px */
  font-weight: ${fontWeights.normal};
  line-height: ${lineHeights.s};
  ${styledFunctions};
`

export const H5 = styled.h5`
  margin: 0;
  font-size: ${fontSizes.l}; /* figma: 18px */
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.s};
  ${styledFunctions};
`

export const H6 = styled.h6`
  margin: 0;
  text-transform: uppercase;
  font-size: ${fontSizes.s}; /* figma: 14px */
  font-weight: ${fontWeights.medium};
  line-height: ${lineHeights.s};
  ${styledFunctions};
`

export const Text = styled.p`
  margin: 0;
  font-weight: ${fontWeights.normal};
  opacity: ${({ opacity }) => opacity};
  white-space: ${({ whiteSpace }) => whiteSpace};
  ${({ antiAliased }) =>
    antiAliased &&
    `
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  `}
  ${({ truncateText }) =>
    truncateText &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
  `}
  ${styledFunctions};
`

Text.defaultProps = {
  fontStyle: 'normal',
  fontSize: 's', // figma: 14px,
  opacity: 1.0,
  lineHeight: lineHeight.s,
}
