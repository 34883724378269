import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, height, maxWidth, maxHeight, display, opacity, borderRadius } from 'styled-system'

const StyledImg = styled.img`
  object-fit: ${({ objectFit }) => objectFit || 'contain'};
  ${width};
  ${height};
  ${maxWidth};
  ${maxHeight};
  ${space};
  ${display};
  ${opacity};
  ${borderRadius};
`

const Image = ({ source, alt, ...props }) => <StyledImg src={source} alt={alt} {...props} />

Image.defaultProps = {
  alt: '',
}

Image.propTypes = {
  source: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
