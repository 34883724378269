import React from 'react'

const withoutPageDataProps = (Component) => {
  return class withoutPageDataProps extends React.Component {
    static async getInitialProps(ctx) {
      return (Component.getInitialProps ? await Component.getInitialProps(ctx) : {}) || {}
    }
    componentDidMount() {
      if (process.browser) {
        const elem = document.getElementById('__NEXT_DATA__')
        if (elem) {
          elem.parentElement.removeChild(elem)
        }
      }
    }
    render() {
      return <Component {...this.props} />
    }
  }
}
export default withoutPageDataProps
