import styled from 'styled-components'
import { width, height, space as styledSpace, maxWidth as styledMaxWidth } from 'styled-system'
import { transparentize, desaturate } from 'polished'
import { space, fontWeights, fontSizes, radius } from 'theme'
import { pxToRem } from 'helpers'

const ButtonBase = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${radius.l};
  padding-left: ${space.xl};
  padding-right: ${space.xl};
  min-width: ${space.xxl};
  font-weight: ${fontWeights.normal};
  font-size: ${fontSizes.m};
  height: ${pxToRem(40)};
  user-select: none;
  color: ${({ theme }) => theme.colors.white};
  transition: background-color 0.12s ease-in-out, border-color 0.12s ease-in-out, color 0.15s ease-in-out;
  ${({ isSmallFont }) =>
    isSmallFont &&
    `
      font-size: ${fontSizes.s};
    `};
  ${({ isSmall }) =>
    isSmall &&
    `
      height: ${pxToRem(30)};
      font-size: ${fontSizes.s};
    `};
  ${({ isDisabled }) =>
    isDisabled &&
    `
      pointer-events: none;
  `};
  ${width};
  ${height};
  ${styledSpace};
  ${styledMaxWidth};
`

const Basic = styled(ButtonBase)``

const Primary = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  display: flex;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.colors.primary_button_border};
  background: ${({ theme }) => theme.colors.primary_button_background};
  color: ${({ theme }) => theme.colors.white};
  ${({ isDisabled, primaryColor, theme }) =>
    isDisabled &&
    `
    background-color: ${transparentize(0.5, theme.colors[primaryColor] || theme.colors.primary)};
  `}
`

const Error = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  background-color: ${({ theme }) => theme.colors.error};
  color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.error};
  &:hover {
    background-color: ${({ theme }) => theme.colors.error};
  }
`

const Gray = styled(ButtonBase)`
  padding-left: ${space.s};
  padding-right: ${space.s};
  min-width: ${space.xl};
  background-color: ${({ theme }) => theme.colors.gray_button_background};
  color: ${({ theme }) => theme.colors.gray_button_color};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const Secondary = styled(ButtonBase)`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.primary)};
  color: ${({ theme }) => theme.colors.primary};
`

const Bordered = styled(ButtonBase)`
  padding-left: ${space.m};
  padding-right: ${space.m};
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.border_color};
  box-shadow: 0 4px 10px ${({ theme }) => transparentize(0.7, theme.colors.box_shadow)};
  ${styledSpace};
`

const Reversed = styled(ButtonBase)`
  display: flex;
  text-decoration: none;
  border: 1px solid ${({ theme }) => theme.colors.border_color_light};
  background-color: ${({ isDisabled, theme }) =>
    isDisabled ? desaturate(0.3, theme.colors.white) : theme.colors.white};
  color: ${({ theme }) => theme.colors.primary};
  border-radius: ${radius.l};
  ${({ isDisabled }) => isDisabled && `cursor: initial;`}
  &:hover {
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.white};
  }
`

const Gradient = styled(Primary)`
  &:hover {
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.primary};
    svg {
      fill: ${({ theme }) => theme.colors.primary};
    }
  }
`

export default {
  Basic,
  Primary,
  Gray,
  Secondary,
  Bordered,
  Reversed,
  Gradient,
  Error,
}
