import React from 'react'
import PropTypes from 'prop-types'
import SyncLoader from 'react-spinners/SyncLoader'
import { Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'

const ButtonWithLoading = ({ buttonComp, loaderColor, children, isLoading, processText, ...props }) => {
  let ButtonComp = buttonComp
  if (buttonComp.displayName === 'Button__Gradient' && isLoading) {
    ButtonComp = Button.Primary
  }
  return (
    <ButtonComp isDisabled={isLoading} {...props}>
      {children}
      {isLoading && (
        <Box ml="s">
          <SyncLoader size="5" color={loaderColor} />
        </Box>
      )}
    </ButtonComp>
  )
}

ButtonWithLoading.defaultProps = {
  buttonComp: Button.Gradient,
  loaderColor: 'white',
  primaryColor: null,
  isLoading: false,
  processText: '',
}

ButtonWithLoading.propTypes = {
  buttonComp: PropTypes.any,
  loaderColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  primaryColor: PropTypes.string,
  processText: PropTypes.string,
}

export default ButtonWithLoading
